<template>
  <div id="auth-view">
    <v-main>
      <v-container fluid class="pa-5 fill-height align-center justify-center">
        <v-row justify="center" align="center">
          <v-col cols="12" justify="center" align="center">
            <div class="text-center" v-html="require(`!html-loader!@/assets/img/gcf-logo.svg`)"></div>
          </v-col>
          <v-col cols="12" justify="center" align="center">
            <v-card class="text-center" width="440" color="secondaryBackgroundColor">
              <v-container>
                <v-row align="center" justify="center" class="pa-5">
                  <v-col cols="12">
                    <router-view/>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
  export default {
    name: 'auth-view',
    data() {
      return {};
    },
    methods: {},
    mounted() {}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  #auth-view {
    background-image: url('../assets/img/gcf-background.svg') ;//'@/assets/img/gcf-background.svg';
        /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow:inset 0 0 0 2000px rgba(29, 29, 29, 0.75);
    #auth-view-image {
      svg {
        width: auto;
        height: auto;
      }
    }
  }
</style>